module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Carrot quest","short_name":"Carrot quest","start_url":"/","background_color":"#ffffff","theme_color":"#FF7C16","icon":"src/images/cq-favicon.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"96a45c0faf692a8fbe589ce0823f0ccc"},
    },{
      plugin: require('../node_modules/gatsby-plugin-carrotquest/gatsby-browser.js'),
      options: {"plugins":[],"carrotquestId":"100-675e2c8860097948c325b680f4da"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
